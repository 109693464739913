
export const unAuthenticatedRoutes = {
    LOGIN: "/login",
    SIGN_UP: "/signup",
    RESET_PASSWORD: "/reset-password",
    FORGET_PASSWORD: "/forget-password",
    EMAIL_SENT: "/email-sent",
    VERIFY_EMAIL: "/verify-email",
    EMAIL_VERIFICATION: "/email-verification/:token",
    PASSWORD_CHANDED: "/password-changed",
    THANKYOU: "/thankyou",
    REVIEW_DOCUMENT: "/document-review"
};

export const authenticatedRoutes = {
    // common routes
    HOME: "/",
    NotFound: "/404",
    Unauthorize: "/403",
    ErrorPage: "/500",
    ADMIN_DASHBOARD: "/admin/dashboard",
    DASHBOARD: "/dashboard",
    THANKYOU: "/thankyou",
    REVIEW_DOCUMENT: "/document-review",
    // admin routes
    CONTACT_LIST: "/admin/contact-list",
    ALL_PENDING_FORMS: "/admin/all-pending-forms",
    MY_PENDING_FORMS: "/admin/my-pending-forms",
    MY_TASK: "/admin/my-tasks",
    ASSIGNED_TASKS: "/admin/assigned-tasks",
    TASK_DETAIL: "/task-detail/:id",
    CONTACT_IMPORT: "/admin/contact-import",
    CONTACT_ADD: "/admin/contact-add",
    MANAGE_USERS: "/admin/manage-users",
    INACTIVE_USERS: "/admin/inactive-users",
    DELETE_USERS: "/admin/delete-users",
    ADD_USER: "/admin/manage-users/add-user",
    EDIT_USER: "/admin/manage-users/edit-user",
    // MANAGE_DOCUMENTS_ADMIN: "/admin/manage-documents-admin",
    ASSIGN_DOCUMENT: "/admin/manage-documents-admin/assign-document",
    ADD_CALENDAR: "/admin/add-calendar",
    APPOINTMENTS: "/admin/appointments",
    CONTACT_USERS: "/admin/contact-list/contact-user/:id",
    RELATION_USERS: "admin/contact-list/contact-user",
    ROLES: "/admin/roles",
    ADD_ROLES: "/admin/roles/add-roles",
    EDIT_ROLES: "/admin/roles/edit-role",
    STATE_AUDITS: "/admin/state-audits",
    CREATE_USER: "/admin/state-audits/create-user",

    USERAUDIT: "/user/audit-profile/",
    USERAUDITPROFILE: "/user/audit-profile/:id",
    USER_STATE_AUDIT: "/user/state-audit",

    DOCUMENT: "/admin/document",
    ADD_DOCUMENT: "/admin/add-document",
    // user dashboard routes
    ROLE_BASE_LISTING: "/user/role-base-listing",
    UPLOADED: "/user/user-uploaded",
    USERAPPOINTMENTS: "/user/user-appointments",
    USER_DOCUMENT: "/user/user-document",
    NURSE_DOCUMENT: "/user/nurse-document",
    USERINVOICES: "/user/user-invoices",
    INVOICES: "/admin/invoices",
    ACCOUNT_BILLING: "/admin/account-billing",
    VIEW_INVOICE: "/admin/view-invoice",

    ASSIGNED: "/user/user-assigned",
    FOSTER_PROFILE: "/user/foster-profile",
    COMPOSE_EMAIL: "/user/compose-email",
    COMPOSE_DRAFT_EMAIL: "/user/compose-draft-email/:id",
    SEND_EMAIL: "/user/send",
    EMAIL_DETAIL: "/user/email/detail",
    DRAFT_EMAIL: "/user/draft-email",
    // ACTIVITY_LOGS: "/activity-logs",
    WARNING_DETAILS: "/warning-details/:type",
    WARNING_LISTING: "/warning-listing",
    ACTIVITY_LOGS: "/admin/activity-logs",
    AUDIT_LISTING: "/admin/:directory/audit-listing",
    EDIT_AUDIT_LISTING: "/admin/:directory/edit-audit-listing",
    AUDIT_PROFILE: "/admin/:directory/audit-profile/:id",
    EDIT_AUDIT_PROFILE: "/admin/:directory/edit-audit-profile/:id",
    ADMIN_LINKS: "/admin/links",
    CASE_LOAD: "/user/case-load"
};

export const permissionGroups = {
    // AdminDashboard: "AdminDashboard",
    // AdminUserManagement: "AdminUserManagement",
    // AdminStateAudits: "AdminStateAudits",
    // AdminActivityLogs: "AdminActivityLogs",
    // AdminDocumentManagement: "AdminDocumentManagement",
    // AddCalendar: "AddCalendar",
    UserDocumentManagement: "UserDocumentsManagement",
    UserDocumentsReviewManagement: "UserDocumentsReviewManagement",

    // AuditManagement

    UserManagement: "UserManagement",
    AssignTaskManagement: "AssignTaskManagement",
    AppointmentManagement: "AppointmentManagement",
    RoleManagement: "RoleManagement",
    SendingEmails: "SendingEmails",
    InvoiceManagement: "InvoiceManagement",
    StateAuditManagement: "AuditManagement",
    DocumentManagement: "DocumentsManagement",
    DashboardStatistics: "DashboardStatistics",
    ContactProfileLeave: "ContactProfileLeave",
    NotesComments: "NotesComments",
    NotesManagement: "NotesManagement",
    UserPermissionManagement: "UserPermissionManagement",
    TaskCommentsManagement: "TaskCommentsManagement",
    UserRelationManagement: "UserRelationManagement",
    DocumentsCategoryManagement: "DocumentsCategoryManagement",
    AccountAndBilling: "AccountAndBilling"
};

export const permissionsConstants = {
    ReassignInvoice: "reassign-invoice",
    ExportDocument: "export-documents",
    InactiveUsers: "inactive-user",
    UserListing: "user-listing",
    UserCreate: "user-create",
    UserDetails: "user-details",
    UserUpdate: "user-update",
    UserDelete: "user-delete",
    AllPendingForms: "all-pending-documents-listing",
    MyPendingForms: "my-pending-documents-listing",
    UserRelationListing: "user-relation-listing",
    UserRelationCreate: "user-relation-create",
    UserRelationDetails: "user-relation-details",
    UserRelationUpdate: "user-relation-update",
    UserRelationPayRate: "user-relation-pay-rate",
    UserRelationDelete: "user-relation-delete",
    roleListing: "role-listing",
    roleCreate: "role-create",
    roleDetails: "role-details",
    roleUpdate: "role-update",
    UserListOnlyRelationUsers: "user-listing-roles-specified",
    UserCaseloadList: "user-listing-only-relations",
    roleDelete: "role-delete",
    permissionListing: "permission-listing",
    permissionDetails: "permission-details",
    permissionUpdate: "permission-update",
    permissionDelete: "permission-delete",
    RolePermissionUpdate: "role-permission-update",
    documentListing: "documents-listing",
    documentCreate: "documents-create",
    documentDetails: "documents-details",
    documentUpdate: "documents-update",
    documentDelete: "documents-delete",
    notesListing: "notes-listing",
    notesCreate: "notes-create",
    notesDetails: "notes-details",
    notesUpdate: "notes-update",
    notesDelete: "notes-delete",
    SendingUpdateEmails: "send-email",
    invoiceListing: "invoice-listing",
    invoiceCreate: "invoice-create",
    invoiceDetails: "invoice-details",
    invoiceUpdate: "invoice-update",
    invoiceDelete: "invoice-delete",
    assignTaskListing: "assign-task-listing",
    myAssignTaskListing: "my-assign-task-listing",
    assignTaskCreate: "assign-task-create",
    assignTaskDetails: "assign-task-details",
    assignTaskUpdate: "assign-task-update",
    assignTaskDelete: "assign-task-delete",
    TaskCommentListing: "task-comment-listing",
    TaskCommentCreate: "task-comment-create",
    TaskCommentUpdate: "task-comment-update",
    TaskCommentDelete: "task-comment-delete",
    appointmentListing: "appointment-listing",
    appointmentCreate: "appointment-create",
    appointmentDetails: "appointment-details",
    appointmentUpdate: "appointment-update",
    appointmentDelete: "appointment-delete",
    contactsOnboarded: "contacts-onboarded",
    caseloadDemographics: "caseload-demographics",
    caseloadActivity: "caseload-activity",
    documentWarningOnTopBar: "document-warning-on-top-bar",
    systemNotificationOnTopBar: "system-notification-on-top-bar",
    temporarySuspensions: "temporary-suspensions",
    documentWarning: "document-warning",
    contactProfileLeave: "contact-profile-leave",
    hrDocumentListing: "user-documents-listing-hr",
    nurseDocumentListing: "user-documents-listing-nurse",
    contractsDocumentListing: "user-documents-listing-contracts",
    accountBillingDocumentListing: "user-documents-listing-account-billing",
    frontDeskDocumentListing: "user-documents-listing-front-desk",
    otherDocumentListing: "user-documents-listing-other",
    programManagerDocumentListing: "user-documents-listing-program-manager",
    contactDocumentsCreate: "user-documents-create",
    userWarningUpdate: "user-warning-update",
    userDocumentsFormCreate: "user-documents-form-create",
    contactDocumentsUpdate: "user-documents-update",
    contactDocumentsDelete: "user-documents-delete",
    userDocumentsStatusUpdate: "user-documents-status-update",
    userDocumentListingCharts: "user-documents-listing-charts",
    CCCDocumentListing: "user-documents-listing-ccc",
    repPayeeDocumentslisting: "user-documents-listing-rep-payee",
    // referralDocumentListing: "user-documents-listing-referral",
    referralDocumentListing: "user-documents-listing-referral",
    // userDocumentsListingPurge: "user-documents-listing-purge",
    userDocumentsSignatureHistory: "user-documents-signature-history",

    notesCommentListing: "notes-comment-listing",
    notesCommentCreate: "notes-comment-create",
    notesCommentDetails: "notes-comment-details",
    notesCommentUpdate: "notes-comment-update",
    notesCommentDelete: "notes-comment-delete",

    PurgeDocumentsListing: "user-documents-listing-purge",

    DocumentsCategoryListing: "documents-category-listing",
    DocumentsCategoryCreate: "documents-category-create",
    DocumentsCategoryDelete: "documents-category-delete",
    DocumentsCategoryUpdate: "documents-category-update",
    UserDocumentsWarnings: "user-document-warnings",
    MyAppointmentListing: "my-appointment-listing",
    AuditAssignAccess: "audit-assign-access",
    AuditEditAccess: "audit-edit-access",
    AuditDeleteAccess: "audit-delete-access",
    AuditProfileDetails: "audit-profile-details",
    AuditProfileListing: "audit-profile-listing",
    userDocumentsReview: "user-documents-review",
    userDocumentsUpdateProofCopy: "user-documents-update-proof-copy",
    UserDocumentsEmailReviewListing: "user-documents-review-listing",
    UserDocumentsEmailReviewDelete: "user-documents-review-delete",
    DocumentScheduleCreate: "user-form-document-schedule-create",
    DocumentScheduleDelete: "user-form-document-schedule-delete",
    DocumentScheduleListing: "user-form-document-schedule-listing",
    DocumentScheduleUpdate: "user-form-document-schedule-update",
    userDocumentsListingProvideNotes: "user-documents-listing-provide-notes",
    dietician: "user-documents-listing-dietician",
    therapist: "user-documents-listing-therapist",
    dentist: "user-documents-listing-dentist",
    userDocumentsUpdateName: "user-documents-update-name",

    // UserDocumentListing: "User document listing",
    // UserDocumentCreate: "User document create",
    // UserDocumentDetails: "User document details",
    // UserDocumentUpdate: "User document update",
    // UserDocumentDelete: "User document delete",

    // assignDocumentsListing: "assign documents listing",
    // assignDocumentsCreate: "assign documents create",
    // assignDocumentsDetails: "assign documents details",
    // assignDocumentsUpdate: "assign documents update",
    // assignDocumentsDelete: "assign documents delete",
    spreadsheetDocumentListing: "spreadsheet-document-listing",
    spreadsheetDocumentCreate: "spreadsheet-document-create",
    spreadsheetDocumentUpdate: "spreadsheet-document-update",
    spreadsheetDocumentDelete: "spreadsheet-document-delete",
    userBasicInformation: "user-basic-information",
    userDetailedInformation: "user-detailed-information",
    updateDocumentAssignee: "update-document-assignee"
};

export const ROLE_TYPES = {
    superAdmin: "super-admin",
    systemCoordinator: "system-coordinator",
    serviceProvider: "service-provider",
    user: "user",
    subAdmin: "sub-admin"
};

/*
add assigned form in user role (done)
and that kind of another things (done)
add condition in state audits (faizan remaining work)
push admin dashboard if role type is system coordinator (done)
remove sub menu things conditionaly like contact add / assigned task /
contact profile conditions (done)



condition in my contact & new contact &
email compose / sent / draft
*/
export const PACKAGE_FEATURE = {
    PACKAGE_1: {
        AuditManagement: "AuditManagement",
        PendingFormManagement: "PendingFormManagement",
        AssignTaskManagement: "AssignTaskManagement",
        AppointmentManagement: "AppointmentManagement"
        // SendingEmails: "SendingEmails"
        // Signature History,
        // Variation
        // Purge Document,
        // Document Variation,
    },
    PACKAGE_2: {
        AuditManagement: "AuditManagement",
        PendingFormManagement: "PendingFormManagement",
        AssignTaskManagement: "AssignTaskManagement"
        // SendingEmails: "SendingEmails"
        // Document Variation,
        // Signature History,
        // Variation
    }
};

export const AllMenusItems = {
    [ROLE_TYPES.superAdmin]: {
        AdminDashboard: {
            text: "Dashboard",
            linkTo: authenticatedRoutes.ADMIN_DASHBOARD,
            selectedOptionKey: "dashboard",
            icon: "kl-dashboard-icons"
        },
        UserManagement: {
            text: "Manage Contacts",
            linkTo: authenticatedRoutes.CONTACT_LIST,
            selectedOptionKey: "contacts",
            icon: "kl-manage-contacts",
            subMenu: true,
            subMenuItems: [
                {
                    text: "New Contacts",
                    linkTo: authenticatedRoutes.CONTACT_ADD,
                    selectedOptionKey: "contact-add"
                },
                {
                    text: "Contact List",
                    linkTo: authenticatedRoutes.CONTACT_LIST,
                    selectedOptionKey: "contact-list"
                },
                {
                    text: "Delete User",
                    linkTo: authenticatedRoutes.DELETE_USERS,
                    selectedOptionKey: "delete-users"
                },
                {
                    text: "InActive Users",
                    linkTo: authenticatedRoutes.INACTIVE_USERS,
                    selectedOptionKey: "inactive-users"
                }
            ]
        },
        PendingFormManagement: {
            text: "Pending Forms",
            icon: "kl-paso-document-list",
            selectedOptionKey: "pending-forms",
            subMenu: true,
            subMenuItems: [
                {
                    text: "All Pending Forms",
                    linkTo: authenticatedRoutes.ALL_PENDING_FORMS,
                    selectedOptionKey: "all-pending-forms"
                },
                {
                    text: "My Pending Forms",
                    linkTo: authenticatedRoutes.MY_PENDING_FORMS,
                    selectedOptionKey: "my-pending-forms"
                }
            ]
        },
        AssignTaskManagement: {
            text: "Task List",
            icon: "kl-paso-document-list",
            selectedOptionKey: "task-list",
            subMenu: true,
            subMenuItems: [
                {
                    text: "My Tasks",
                    linkTo: authenticatedRoutes.MY_TASK,
                    selectedOptionKey: "my-tasks"
                },
                {
                    text: "Assigned Tasks",
                    linkTo: authenticatedRoutes.ASSIGNED_TASKS,
                    selectedOptionKey: "assigned-tasks"
                }
            ]
        },
        AdminUserManagement: {
            text: "Manage Users",
            linkTo: authenticatedRoutes.MANAGE_USERS,
            selectedOptionKey: "manage-users",
            icon: "kl-manage-users"
        },
        AppointmentManagement: {
            text: "Appointments",
            linkTo: authenticatedRoutes.APPOINTMENTS,
            selectedOptionKey: "appointments",
            icon: "kl-appointments"
        },
        AuditManagement: {
            text: "Audit Management",
            icon: "kl-state-audits",
            selectedOptionKey: "audit-management",
            subMenu: true,
            subMenuItems: [
                {
                    text: "Assign Audit Documents",
                    linkTo: authenticatedRoutes.STATE_AUDITS,
                    selectedOptionKey: "assign-audit-documents"
                },
                {
                    text: "Audit Documents",
                    linkTo: authenticatedRoutes.USER_STATE_AUDIT,
                    selectedOptionKey: "audit-documents"
                }
            ]
        },
        AdminActivityLogs: {
            text: "Activity Logs",
            linkTo: authenticatedRoutes.ACTIVITY_LOGS,
            selectedOptionKey: "activity-logs",
            icon: "paso-activitylogicon"
        },
        RoleManagement: {
            text: "Roles",
            linkTo: authenticatedRoutes.ROLES,
            selectedOptionKey: "roles",
            icon: "kl-manage-users"
        },
        SendingEmails: {
            text: "Send Email",
            icon: "kl-paso-send-regular",
            selectedOptionKey: "send-email",
            subMenu: true,
            subMenuItems: [
                {
                    text: "Compose",
                    linkTo: authenticatedRoutes.COMPOSE_EMAIL,
                    selectedOptionKey: "compose-email"
                },
                {
                    text: "Sent",
                    linkTo: authenticatedRoutes.SEND_EMAIL,
                    selectedOptionKey: "send"
                },
                {
                    text: "Draft",
                    linkTo: authenticatedRoutes.DRAFT_EMAIL,
                    selectedOptionKey: "draft-email"
                }
            ]
        },
        DocumentsManagement: {
            text: "Documents",
            icon: "kl-my-tasks",
            selectedOptionKey: "documents",
            subMenu: true,
            subMenuItems: [
                {
                    text: "Add Documents",
                    linkTo: authenticatedRoutes.ADD_DOCUMENT,
                    selectedOptionKey: "add-document"
                },
                {
                    text: "Documents List",
                    linkTo: authenticatedRoutes.DOCUMENT,
                    selectedOptionKey: "document"
                }
            ]
        },
        InvoiceManagement: {
            text: "All Invoices",
            linkTo: authenticatedRoutes.INVOICES,
            selectedOptionKey: "invoices",
            icon: "kl-my-tasks"
        },
        AccountAndBilling: {
            text: "Account and Billing",
            linkTo: authenticatedRoutes.ACCOUNT_BILLING,
            selectedOptionKey: "account-billing",
            icon: "kl-my-tasks"
        }
    },
    [ROLE_TYPES.systemCoordinator]: {
        UserDocumentsManagement: {
            text: "Forms",
            linkTo: authenticatedRoutes.ASSIGNED,
            selectedOptionKey: "user-assigned",
            icon: "kl-my-tasks"
        },
        AdminDashboard: {
            text: "Dashboard",
            linkTo: authenticatedRoutes.ADMIN_DASHBOARD,
            selectedOptionKey: "dashboard",
            icon: "kl-dashboard-icons"
        },
        UserManagement: {
            text: "Manage Contacts",
            linkTo: authenticatedRoutes.CONTACT_LIST,
            selectedOptionKey: "contacts",
            icon: "kl-manage-contacts",
            subMenu: true,
            subMenuItems: [
                {
                    text: "New Contacts",
                    linkTo: authenticatedRoutes.CONTACT_ADD,
                    selectedOptionKey: "contact-add"
                },
                {
                    text: "Contact List",
                    linkTo: authenticatedRoutes.CONTACT_LIST,
                    selectedOptionKey: "contact-list"
                },
                {
                    text: "Delete User",
                    linkTo: authenticatedRoutes.DELETE_USERS,
                    selectedOptionKey: "delete-users"
                },
                {
                    text: "InActive User",
                    linkTo: authenticatedRoutes.INACTIVE_USERS,
                    selectedOptionKey: "inactive-users"
                }
            ]
        },
        RoleManagement: {
            text: "Roles",
            linkTo: authenticatedRoutes.ROLES,
            selectedOptionKey: "roles",
            icon: "kl-manage-users"
        },
        SendingEmails: {
            text: "Send Email",
            icon: "kl-paso-send-regular",
            selectedOptionKey: "send-email",
            subMenu: true,
            subMenuItems: [
                {
                    text: "Compose",
                    linkTo: authenticatedRoutes.COMPOSE_EMAIL,
                    selectedOptionKey: "compose-email"
                },
                {
                    text: "Sent",
                    linkTo: authenticatedRoutes.SEND_EMAIL,
                    selectedOptionKey: "send"
                },
                {
                    text: "Draft",
                    linkTo: authenticatedRoutes.DRAFT_EMAIL,
                    selectedOptionKey: "draft-email"
                }
            ]
        },
        DocumentsManagement: {
            text: "Documents",
            icon: "kl-my-tasks",
            selectedOptionKey: "documents",
            subMenu: true,
            subMenuItems: [
                {
                    text: "Add Documents",
                    linkTo: authenticatedRoutes.ADD_DOCUMENT,
                    selectedOptionKey: "add-document"
                },
                {
                    text: "Documents List",
                    linkTo: authenticatedRoutes.DOCUMENT,
                    selectedOptionKey: "document-list"
                }
            ]
        },
        AdminActivityLogs: {
            text: "Activity Logs",
            linkTo: authenticatedRoutes.ACTIVITY_LOGS,
            selectedOptionKey: "activity-logs",
            icon: "paso-activitylogicon"
        },
        InvoiceManagement: {
            text: "Invoices",
            linkTo: authenticatedRoutes.USERINVOICES,
            selectedOptionKey: "user-invoices",
            icon: "kl-my-tasks"
        }
    },
    [ROLE_TYPES.user]: {
        UserDocumentsManagement: {
            text: "Forms",
            linkTo: authenticatedRoutes.ASSIGNED,
            selectedOptionKey: "user-assigned",
            icon: "kl-my-tasks"
        },
        InvoiceManagement: {
            text: "Invoices",
            linkTo: authenticatedRoutes.USERINVOICES,
            selectedOptionKey: "user-invoices",
            icon: "kl-my-tasks"
        },
        SendingEmails: {
            text: "Send Email",
            icon: "kl-paso-send-regular",
            selectedOptionKey: "send-email",
            subMenu: true,
            subMenuItems: [
                {
                    text: "Compose",
                    linkTo: authenticatedRoutes.COMPOSE_EMAIL,
                    selectedOptionKey: "compose-email"
                },
                {
                    text: "Sent",
                    linkTo: authenticatedRoutes.SEND_EMAIL,
                    selectedOptionKey: "send"
                },
                {
                    text: "Draft",
                    linkTo: authenticatedRoutes.DRAFT_EMAIL,
                    selectedOptionKey: "draft-email"
                }
            ]
        },

        UserManagement: {
            text: "Manage Contacts",
            linkTo: authenticatedRoutes.CONTACT_LIST,
            selectedOptionKey: "contacts",
            icon: "kl-manage-contacts",
            subMenu: true,
            subMenuItems: [
                {
                    text: "New Contacts",
                    linkTo: authenticatedRoutes.CONTACT_ADD,
                    selectedOptionKey: "contact-add"
                },
                {
                    text: "Contact List",
                    linkTo: authenticatedRoutes.CONTACT_LIST,
                    selectedOptionKey: "contact-list"
                },
                {
                    text: "Delete User",
                    linkTo: authenticatedRoutes.DELETE_USERS,
                    selectedOptionKey: "delete-users"
                },
                {
                    text: "InActive User",
                    linkTo: authenticatedRoutes.INACTIVE_USERS,
                    selectedOptionKey: "inactive-users"
                }
            ]
        },

        RoleManagement: {
            text: "Roles",
            linkTo: authenticatedRoutes.ROLES,
            selectedOptionKey: "roles",
            icon: "kl-manage-users"
        }
    },
    [ROLE_TYPES.serviceProvider]: {
        UserDocumentsManagement: {
            text: "Forms",
            linkTo: authenticatedRoutes.ASSIGNED,
            selectedOptionKey: "user-assigned",
            icon: "kl-my-tasks"
        },
        InvoiceManagement: {
            text: "Invoices",
            linkTo: authenticatedRoutes.USERINVOICES,
            selectedOptionKey: "userinvoices",
            icon: "kl-my-tasks"
        },
        SendingEmails: {
            text: "Send Email",
            icon: "kl-paso-send-regular",
            selectedOptionKey: "uploaded",
            subMenu: true,
            subMenuItems: [
                {
                    text: "Compose",
                    linkTo: authenticatedRoutes.COMPOSE_EMAIL,
                    selectedOptionKey: "compose-email"
                },
                {
                    text: "Sent",
                    linkTo: authenticatedRoutes.SEND_EMAIL,
                    selectedOptionKey: "send"
                },
                {
                    text: "Draft",
                    linkTo: authenticatedRoutes.DRAFT_EMAIL,
                    selectedOptionKey: "draft-email"
                }
            ]
        },
        UserManagement: {
            text: "Manage Contacts",
            linkTo: authenticatedRoutes.CONTACT_LIST,
            selectedOptionKey: "contacts",
            icon: "kl-manage-contacts",
            subMenu: true,
            subMenuItems: [
                {
                    text: "New Contacts",
                    linkTo: authenticatedRoutes.CONTACT_ADD,
                    selectedOptionKey: "contact-add"
                },
                {
                    text: "Contact List",
                    linkTo: authenticatedRoutes.CONTACT_LIST,
                    selectedOptionKey: "contact-list"
                },
                // {
                //     text: "All Pending Forms",
                //     linkTo: authenticatedRoutes.ALL_PENDING_FORMS,
                //     selectedOptionKey: "all-pending-forms"
                // },
                // {
                //     text: "My Pending Forms",
                //     linkTo: authenticatedRoutes.MY_PENDING_FORMS,
                //     selectedOptionKey: "pending-forms"
                // },
                {
                    text: "Delete User",
                    linkTo: authenticatedRoutes.DELETE_USERS,
                    selectedOptionKey: "delete-users"
                },
                {
                    text: "InActive User",
                    linkTo: authenticatedRoutes.INACTIVE_USERS,
                    selectedOptionKey: "inactive-users"
                }
            ]
        },
        RoleManagement: {
            text: "Roles",
            linkTo: authenticatedRoutes.ROLES,
            selectedOptionKey: "roles",
            icon: "kl-manage-users"
        }
    }
};

