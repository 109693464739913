import { ADMIN_SLUG, STORAGE_CONST } from "../constants/constant";
import { permissionsConstants } from "../routes/constant";
import { ROLES_NAMES } from "./constants/constant";
import LocalStorageService from "./local-storage.service";
import utilService from "./utils.service";

const AuthService = {
    isTokenExist,
    getToken,
    getUserInfo,
    getRole,
    getRoleSlug,
    getRoleObject,
    getCurrentUserId,
    getPermissionsGroups,
    getPermissionsSlugs,
    getPermissionsGroupsRedux,
    getPermissionsSlugsRedux,
    isAdmin,
    getUserCreateRoleIds
};

function isAdmin() {
    const isAdmin =
        AuthService.getRole() === ROLES_NAMES.ADMIN || AuthService.getRoleSlug() === ADMIN_SLUG ? true : false;
    return isAdmin;
}

function getRole() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user?.role?.name;
    }
    return null;
}

function getRoleSlug() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user?.role?.slug;
    }
    return null;
}

function getRoleObject() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user?.role;
    }
    return null;
}

function getPermissionsGroups() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user?.userPermissions?.map((singlePermission) => {
            return utilService.pascalCase(singlePermission?.permissions?.attributes?.group);
        });
    }
    return null;
}

function getUserCreateRoleIds() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        const findUserCreatePermission = userInfo?.user?.userPermissions?.find((singleUserPermission) => {
            return singleUserPermission?.permissions?.attributes?.slug === permissionsConstants.UserCreate;
        });

        const roleIds = findUserCreatePermission?.rolesPermissionQuery?.map(
            (singleRolePermision) => singleRolePermision?.value
        );

        return roleIds[0];
    }
    return null;
}

function getPermissionsSlugs() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user?.userPermissions?.map((singlePermission) => {
            // return utilService.pascalCase(singlePermission?.permissions?.attributes?.slug);
            return singlePermission?.permissions?.attributes?.slug;
        });
    }
    return null;
}

function getPermissionsGroupsRedux(userInfo) {
    if (userInfo) {
        return userInfo?.userPermissions?.map((singlePermission) => {
            return utilService.pascalCase(singlePermission?.permissions?.attributes?.group);
        });
    }
    return null;
}

function getPermissionsSlugsRedux(userInfo) {
    if (userInfo) {
        return userInfo?.userPermissions?.map((singlePermission) => {
            // return utilService.pascalCase(singlePermission?.permissions?.attributes?.slug);
            return singlePermission?.permissions?.attributes?.slug;
        });
    }
    return null;
}

function getCurrentUserId() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user?.id;
    }
    return null;
}

function isTokenExist() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        return true;
    }
    return false;
}

function getToken() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
     
        return userInfo.token;
    }
    return null;
}

function getUserInfo() {
    let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
    if (userInfo) {
        return JSON.parse(userInfo);
    }
    return null;
}

export default AuthService;
